import React, { useState, useEffect } from 'react';
import { Link, NavLink, useParams, useLocation } from 'react-router-dom';
import catalogData from '../../data/catalogData.json';
import * as FaIcons from "react-icons/fa6";
import './header.css'
import logo from '../../media/vad_logo.png'

const Header = () => {
    const { productSlug } = useParams();
    const products = productSlug ? catalogData.products.filter(p => p.slug === productSlug) : catalogData.products;

    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    // Handle scroll effect
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setIsScrolled(scrollPosition > 20); // Change color after 50px scroll
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup listener
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Close menu when route changes
    useEffect(() => {
        setIsMenuOpen(false);
        const menuBtn = document.getElementById('close-btn');
        if (menuBtn) menuBtn.checked = true;
    }, [location]);

    // Handle menu toggle
    const handleMenuToggle = (isOpen) => {
        setIsMenuOpen(isOpen);
    };

return (
<header>
    <nav className={`${ isScrolled ? 'scrolled' : '' }`}>
        <div className="container wrapper">
            <div className="logo">
                <Link to="/"><img src={logo} alt="VA Digimedia" /></Link>
            </div>
            <input type="radio" name="slider" id="menu-btn" onChange={() => handleMenuToggle(true)}/>
            <input type="radio" name="slider" id="close-btn" onChange={() => handleMenuToggle(false)}/>

            <ul className={`nav-links mb-0 ${isMenuOpen ? 'active' : ''}`}>
                <label htmlFor="close-btn" className="btn close-btn" onClick={() => handleMenuToggle(false)}><FaIcons.FaX/></label>
                <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/">Home</NavLink></li>
                <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/about">About</NavLink></li>
                {/* <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/products">Products</NavLink></li> */}
                <li>
                    <NavLink className={({ isActive }) => isActive ? 'desktop-item active' : 'desktop-item'} to="/products">Products</NavLink>
                    <input type="checkbox" id="showDrop"/>
                    <label htmlFor="showDrop" className="mobile-item">Products</label>
                    <ul className="drop-menu">
                        {products.map(product => (
                            <li key={product.id}>
                                <NavLink to={`/products/${product.slug}`}>
                                    {product.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </li>
                <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/contact">Contact</NavLink></li>
            </ul>
            <label htmlFor="menu-btn" className="btn menu-btn" onClick={() => handleMenuToggle(true)}><FaIcons.FaBars/></label>
        </div>
    </nav>
</header>
);
};

export default Header;