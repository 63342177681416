import { Link } from 'react-router-dom';
import ItemCard from './itemcard/ItemCard';

const ItemList = ({ items }) => (
    <div className="row">
        {items.map(item => (
            <ItemCard key={item.id} item={item} />
        ))}
    </div>
);

export default ItemList;