import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GoToTop from '../components/GoToTop'
import ProductList from '../components/sections/products/ProductList';
import { HelmetAsync } from '../components/HelmetAsync';

const Products = () => {

    const location = useLocation();
    const seoTitle = `Products - VA Digimedia`;

    useEffect(() => {
        // Force document title update
        document.title = seoTitle;
    }, [location, seoTitle]);

return (
<section className="page">
    <HelmetAsync
        key={location.pathname} // Force remount on route change
        title={seoTitle}
        description="Explore our range of products and services"
        keywords="products, services, solutions, technology, va digimedia products, value addict services"
    />
    <div className="container pb-5">
        <h1 className='mb-4'>Products</h1>
        <ProductList/>
        <GoToTop />
    </div>
</section>
);
};

export default Products;