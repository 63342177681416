import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import GoToTop from './GoToTop'
import { HelmetAsync } from './HelmetAsync'

function NotFound() {
    const location = useLocation();
    const seoTitle = `Error 404 - VA Digimedia`;

    useEffect(() => {
        // Force document title update
        document.title = seoTitle;
    }, [location, seoTitle]);

    return (
        <section className="page">
            <HelmetAsync
                key={location.pathname} // Force remount on route change
                title={seoTitle}
                body='homebody'
            />
            <div className="container pb-5">
                <h1 className="mb-4">Error 404</h1>

                <div id='main' className="container py-4 text-center">
                    <p className='lead'>Oops..!! Page Not Found</p>
                </div>
            </div>
            <GoToTop />
        </section>
    )
}

export default NotFound