import { Link, useParams } from 'react-router-dom';
import catalogData from '../../../data/catalogData.json'

const ItemPage = () => {
    const { itemSlug } = useParams();
    const item = catalogData.items.find(p => p.slug === itemSlug);
  
    if (!item) return <div className="container mt-4"><h2>Item Not Found</h2></div>;
  
    return (
        <div className="container mb-4">
            <h1>{item.name}</h1>
            <p><strong>product:</strong> {item.product}</p>
            <p><strong>subproduct:</strong> {item.subproduct}</p>
            <p><strong>Price:</strong> ${item.price}</p>
            <p>{item.description}</p>
        </div>
    );
};

export default ItemPage;