import React from 'react'
import {BrowserRouter as Router, Routes, Route} from  'react-router-dom'
// import Navigation from './components/nav/Navigation'
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
// import CategoriesPage from './components/sections/products/CategoriesPage'
import ProductPage from './components/sections/products/ProductPage'
import ItemPage from './components/sections/products/ItemPage'
import Products from './pages/Products'
import './css/global.css'
import NotFound from './components/NotFound'

function MainRoutes() {
    return (
        <Router>
            {/* <Navigation/> */}
            <Header/>
            <main>
            <Routes>
                <Route index element={<Home />} />
                <Route path='*' element={<NotFound />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<Contact />} />
                {/* <Route path="products" element={<CategoriesPage />} /> */}
                <Route path="products" element={<Products />} />
                <Route path="products/:productSlug" element={<ProductPage />} />
                <Route path="products/:productSlug/:subproductSlug" element={<ProductPage />} />
                <Route path="products/:productSlug/:subproductSlug/:itemSlug" element={<ItemPage />} />
            </Routes>
            </main>
            <Footer />
        </Router>
    )
}

export default MainRoutes