import React, { useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import catalogData from '../../../data/catalogData.json'
import ItemList from './ItemList'
import * as FaIcons from "react-icons/fa6";
import * as MdIcons from "react-icons/md";
import GoToTop from '../../../components/GoToTop'
import { HelmetAsync } from '../../HelmetAsync';

const ProductPage = () => {
    const { productSlug, subproductSlug } = useParams();
    const product = catalogData.products.find(c => c.slug === productSlug);
    const subproduct = product?.subproducts?.find(s => s.slug === subproductSlug);
    
    let items = catalogData.items.filter(p => p.product === productSlug);
    if (subproductSlug) {
        items = items.filter(p => p.subproduct === subproductSlug);
    }

    const getIconLibrary = (library) => {
        switch (library.toLowerCase()) {
            case 'fa':
            return FaIcons;
            case 'md':
            return MdIcons;
            default:
            return null;
        }
        };
    
        const renderIcon = (iconName, library) => {
        const iconLibrary = getIconLibrary(library);
        
        if (!iconLibrary) {
            console.warn(`Icon library ${library} not found`);
            return null;
        }
    
        const IconComponent = iconLibrary[iconName];
        
        if (IconComponent) {
            return <IconComponent />;
        }
        
        console.warn(`Icon ${iconName} not found in ${library} library`);
        return null;
    };

    const location = useLocation();

    const seoTitle = `${product.name} - VA Digimedia`;
  
    useEffect(() => {
        // Force document title update
        document.title = seoTitle;
    }, [location, seoTitle]);
  
return (
    <section className="page">
        <HelmetAsync
            key={location.pathname}
            title={seoTitle}
            description={product.description}
            keywords={product.keywords}
        />
        <div className="container pb-5">
            <h1 className='mb-4'>{subproduct ? subproduct.name : product ? product.name : 'product Not Found'}</h1>
            <div className="row">
                <div className="col-lg-8">
                    <p className='lead'>{subproduct ? subproduct.description : product ? product.description : 'product Not Found'}</p>
                </div>
                <div className="col product-page">
                    {renderIcon(product.icon, product.library)}
                </div>
            </div>
            <ItemList items={items} />
            <GoToTop/>
        </div>
    </section>
);
};

export default ProductPage;