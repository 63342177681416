import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import * as FaIcons from "react-icons/fa6";
import aboutData from '../data/about.json'
import contentData from '../data/content.json'
import '../css/ContactPage.css';
import GoToTop from '../components/GoToTop'
import { HelmetAsync } from '../components/HelmetAsync';
import ContentDisplay from '../components/sections/ContentDisplay';

const ContactPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would typically send the form data to your backend
        console.log('Form submitted:', formData);
        // Reset form after submission
        setFormData({ name: '', email: '', subject: '', message: '' });
    };

    const location = useLocation();
    const seoTitle = `Contact - VA Digimedia`;

    useEffect(() => {
        // Force document title update
        document.title = seoTitle;
    }, [location, seoTitle]);

return (
    <section className="page">
        <HelmetAsync
            key={location.pathname} // Force remount on route change
            title={seoTitle}
        />
        {aboutData.company.map((vad) => (
        <div className="container pb-5" key={vad.id}>
            <h1 className="mb-4">Contact Us</h1>
            <div className="row">
                {/* <div className="col-md-6 mb-4 mb-md-0">
                    <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="subject" className="form-label">Subject</label>
                        <input
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows="5"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">Send Message</button>
                    </form>
                </div> */}
                <div className="col-md-6">
                    <div className="contact-info mb-4 mb-md-0">
                        <h3 className="mb-4">Get in Touch</h3>
                        <p>We'd love to hear from you:</p>
                        <ul className="list-unstyled">
                            <li className="mb-3">
                                <FaIcons.FaEnvelope className="me-2" /> <a href={"mailto:"+vad.email}>{vad.email}</a>
                            </li>
                            <li className="mb-3">
                                <FaIcons.FaPhone className="me-2" /> <a href={"tel:"+vad.phone}>{vad.phone}</a>
                            </li>
                            <li className="mb-3">
                                <FaIcons.FaMapLocation className="me-2" /> {vad.address}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="map-container">
                        <iframe
                            src={vad.gmapembed}
                            width="100%"
                            height="305"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Google Maps"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
        ))}

        {/* <ContentDisplay data={contentData} /> */}
        <GoToTop />
    </section>
);
};

export default ContactPage;

