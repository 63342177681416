import { Link } from 'react-router-dom';
import './itemcard.css'

const ItemCard = ({ item }) => (
    <div className="col-md-4 mb-4">
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{item.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">${item.price}</h6>
                <p className="card-text">{item.description}</p>
                <Link to={`/products/${item.product}/${item.subproduct}/${item.slug}`} className="btn btn-primary">View Details</Link>
            </div>
        </div>
    </div>
);

export default ItemCard;