import React from 'react';
import { Link } from 'react-router-dom';
import * as FaIcons from "react-icons/fa6";
import aboutData from '../../data/about.json'
import logo from '../../media/vad_logo.png'
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
        {aboutData.company.map((vad) => (

        <div className="container pt-5 pb-4" key={vad.id}>
            <div className="row">
                <div className="col-md-5 mb-3 mb-md-0">
                    <h5>About Us</h5>
                    <img className='logo' src={logo} alt="" />
                    <p>{vad.shortdesc}</p>
                </div>
                <div className="col-md-3 mb-3 mb-md-0 links">
                    <h5>Quick Links</h5>
                    <ul className="list-unstyled">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/products">Products</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
                <div className="col-md-4">
                    <h5>Connect With Us</h5>
                    <p>
                        <FaIcons.FaEnvelope /> <a href={"mailto:"+vad.email}>{vad.email}</a><br />
                    </p>
                    <p>
                        <FaIcons.FaPhone /> <a href={"tel:"+vad.phone}>{vad.phone}</a>
                    </p>
                    <div className="social-icons mt-2">
                        <a href="https://www.linkedin.com/company/vadigimedia" className="me-2" target='_blank' rel='noreferrer'><FaIcons.FaLinkedinIn /></a>
                        <a href="https://x.com/vadigimedia" className="me-2" target='_blank' rel='noreferrer'><FaIcons.FaXTwitter /></a>
                        <a href="https://www.facebook.com/vadigimedia" className="me-2" target='_blank' rel='noreferrer'><FaIcons.FaFacebookF /></a>
                        <a href="https://www.instagram.com/vadigimedia" className="me-2" target='_blank' rel='noreferrer'><FaIcons.FaInstagram /></a>
                    </div>
                </div>
            </div>
            <hr className="mt-4 mb-4" />
            <div className="row">
                <div className="col-md-12 text-center text-md-center">
                    <p className='m-0'>&copy; 2025 {vad.officalname}</p>
                </div>
                {/* <div className="col-md-6 text-center text-md-right">
                    <p>
                        <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a>
                    </p>
                </div> */}
            </div>
        </div>
        ))}
    </footer>
  );
};

export default Footer;

