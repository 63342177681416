import MainRoutes from "./MainRoutes";
import { HelmetProvider} from "react-helmet-async";

function App() {
    return (
        <HelmetProvider>
        <div className="App">
            <MainRoutes/>
        </div>
        </HelmetProvider>
    );
}

export default App;