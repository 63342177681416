import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import catalogData from '../data/catalogData.json'
import aboutData from '../data/about.json'
import vaimage from '../media/va_logo.png'
import vadimage from '../media/vad_logo.png'
import * as MdIcons from "react-icons/md";
import HeroSection from '../components/sections/home/HeroSection'
import ProductList from '../components/sections/products/ProductList';
import GoToTop from '../components/GoToTop'
import { HelmetAsync } from '../components/HelmetAsync';

const Home = () => {

    // const featuredItems = catalogData.products.subproducts.slice(0, 5);
    const location = useLocation();
    const seoTitle = `VA Digimedia - Best Bulk SMS, Bulk Email, Promotional Calls Provider in Noida, Delhi NCR, Uttar Pradesh, India | Value Addict Digimedia`;

    useEffect(() => {
        // Force document title update
        document.title = seoTitle;
    }, [location, seoTitle]);

return (
<>
    <HelmetAsync
        key={location.pathname} // Force remount on route change
        title={seoTitle}
        body='homebody'
    />
    {/* <HeroCarousel featuredItems={featuredItems} /> */}
    <section className='home-section'>
        <div className="container pt-2 pb-5 text-center">
            <div className="hero-tagline mb-4"><span><MdIcons.MdMarkUnreadChatAlt/></span> Your Bridge to Better Connections</div>
            {/* <div className="w-100 pt-5">
                <img height="120px" src={vadimage} alt="" />
            </div> */}
            <h3 className='pt-2'>
                Empower Your Business
            </h3>
            <h3 className=''>
                With <span>Communication</span> That Converts
            </h3>
            <p className="lead pb-4">Bulk SMS, Email, Voice, WhatsApp, and Digital Marketing Solutions All in One Place</p>
            <Link to="/products" className="btn btn-hero">Explore Our Solutions <MdIcons.MdDoubleArrow size=""/></Link>
            {/* <div className="row">
                <div className="col-md-7">
                    <h1 className="m-0">
                        Empowering Your Communication
                    </h1>
                    <h2>Bulk SMS, Email, Voice, WhatsApp, and Digital Marketing Solutions All in One Place.</h2>
                </div>
                <div className="col-md-5">
                </div>
            </div> */}
            {/* <HeroSection /> */}
        </div>
    </section>
    {aboutData.company.map((vad) => (
    <section className="alt-home container text-center py-5" key={vad.id}>
            <h2 className='mb-4'>About {vad.name}</h2>
            <div className="row d-flex align-items-center">
                <div className="col text-lg-right order-lg-12 mb-4">
                    <img src={vadimage} alt="" />
                </div>
                <div className="col-lg-7 text-lg-right">
                    <p className='text-justify'>{vad.desc}</p>
                </div>
            </div>
            <Link to="/about" className="btn btn-primary">Know More</Link>
    </section>
    ))}
    <section className='container py-5 text-center'>
        {/* <Link to="/products" className="btn btn-primary">Browse All products</Link> */}
        <h2 className='mb-4'>Products</h2>
        <ProductList/>
    </section>

    <GoToTop/>
</>
);
};

export default Home;